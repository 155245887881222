<template>
  <section class='section'>
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Transacciones</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="control">
            <button
              class="button"
              type="button"
              :class="{ 'is-loading': isLoading }"
              @click="getCsvDownload"
            >
              Descargar
            </button>
          </div>
        </div>
        <div class="level-item">
          <div class="control">
            <input class="input" type="text" v-model="search" @keyup="searchUser" placeholder="Buscador...">
          </div>
        </div>
        <div class="level-item ">
          <div class="select">
            <select class="select" v-model="filter" @change="changeStatus">
              <option value=""> Todos </option>
              <option :value="2"> Pagado </option>
              <option :value="1"> Pendiente </option>
            </select>
          </div>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline is-centered">
      <div class="column is-3">
        <div class="box">
          <p class="dashtitle">Tarjeta</p>
          <p class="dashAmount"><strong>Total Trans.</strong> {{accums.tarjeta ? accums.tarjeta.total : 0}}</p>
          <p class="dashAmount"><strong>Monto</strong> {{numberFormat(accums.tarjeta ? accums.tarjeta.amount : 0)}}</p>
        </div>
      </div>
      <div class="column is-3">
        <div class="box">
          <p class="dashtitle">Oxxo</p>
          <p class="dashAmount"><strong>Total Trans.</strong> {{accums.oxxo ? accums.oxxo.total : accums.oxxo}}</p>
          <p class="dashAmount"><strong>Monto</strong> {{numberFormat(accums.oxxo ? accums.oxxo.amount : accums.oxxo)}}</p>
        </div>
      </div>
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="transactionsList"
          :loading=loading
        >
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '../../mixin/validateDate'
const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Transacciones',
  mixins: [mixins],
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      addUserModal: false,
      modalEditAdmin: false,
      page: 1,
      pages: '',
      loading: false,
      isLoading: false,
      transactionsList: [],
      accums: [],
      search: '',
      filter: '',
      columnsData: [
        {
          id: 'id', header: '#', class: 'column-mobile'
        },
        {
          id: 'username',
          header: 'Usuario',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `usuarios/detalle/${row.userId}`
        },
        {
          id: 'createdAt',
          header: 'Fecha',
          class: 'column-mobile',
          accessor: (row) => this.formatDateTimestamp(row.createdAt, 'YYYY-MM-DD HH:mm')
        },
        {
          id: 'amount',
          header: 'Monto',
          class: 'column-mobile',
          accessor: (row) => this.numberFormat(row.amount)
        },
        {
          id: 'payment_id',
          header: 'Metodo',
          class: 'column-mobile',
          accessor: (row) => row.payment_id ? 'Tarjeta' : 'Oxxo'
        },
        {
          id: 'status',
          header: 'Estado',
          class: 'column-mobile',
          accessor: (row) => row.status === 2 ? 'Pagado' : 'Pendiente'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getTransactions', 'csvDownloadTransactions', 'addTemporalNotification']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.getTransactions({ page, search: this.search, filter: this.filter })
        .then(resp => {
          this.loading = false
          this.transactionsList = resp.payload
          this.pages = resp.totalPages
        })
    },
    searchUser ({ target }) {
      if (target.value.length >= 3 || target.value.length === 0) {
        this.search = target.value
        this.getTransactions({ page: 1, search: target.value, filter: this.filter })
          .then(resp => {
            this.transactionsList = resp.payload
            this.pages = resp.totalPages
            this.loading = false
          })
      }
    },
    changeStatus ({ target }) {
      this.filter = target.value
      this.getTransactions({ page: 1, search: this.search, filter: target.value })
        .then(resp => {
          this.transactionsList = resp.payload
          this.pages = resp.totalPages
          this.loading = false
        })
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadTransactions({ search: this.search, filter: this.filter })
        .then(resp => {
          if (resp) {
            const exportName = 'Export transactions.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getTransactions({ page: this.page, limit: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.transactionsList = resp.payload
        this.pages = resp.totalPages
        this.accums = resp.accums.reduce((accum, paymentType) => {
          accum = { ...accum, [paymentType.payment_type]: { total: paymentType.total, amount: paymentType.total_amount } }
          return accum
        }, {})
        this.loading = false
      })
  }
}
</script>
<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .icon-action {
    cursor: pointer;
    color: #039DA1;
  }
  .dashtitle {
    font-size: 18px;
    text-align: center;
    font-weight: bolder;
  }
  .dashAmount {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
  }
  .stadistics .box:hover {
    background-color: #FAFAFA;
  }
  .field:not(:last-child) {
    margin-bottom: 0rem;
  }
</style>
